import { FormattedMessage } from "react-intl";

import { Checkbox, CheckboxLabel } from "common/core/form/option";
import type { IDVInteraction } from "common/identity_verification/interaction";
import { Heading } from "common/core/typography";

import Styles from "./index.module.scss";

export function DesktopCaptureCheckbox({ idvInteraction }: { idvInteraction: IDVInteraction }) {
  return (
    <>
      <div className={Styles.subHeading}>
        <Heading textStyle="subtitleSmall" level="h2">
          <FormattedMessage
            id="a20bc777-640a-4f3c-a3e0-7c781987ae49"
            defaultMessage="Don't have a mobile phone?"
          />
        </Heading>
      </div>
      <CheckboxLabel
        checkbox={
          <Checkbox
            aria-invalid={false}
            data-automation-id="use-this-device-checkbox"
            checked={idvInteraction.configuration.enableDesktopCapture}
            onChange={(event) =>
              idvInteraction.onConfigurationChange({
                enableDesktopCapture: event.target.checked,
              })
            }
          />
        }
        label={
          <FormattedMessage
            id="3eca522e-4602-4259-9959-4d4885a51e41"
            defaultMessage="Use this device"
          />
        }
      />
    </>
  );
}
