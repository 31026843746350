import { FormattedMessage } from "react-intl";

import { MainHeading } from "common/signer/common";

import type { CommonContentProps } from "..";

export function Heading({
  previousStepWasKba,
  selfieRequired,
  namePrompt,
  requiresIdPhotos,
}: CommonContentProps) {
  return (
    <MainHeading leftAlign>
      {previousStepWasKba ? (
        <FormattedMessage
          id="15c1b2c3-cb5f-4e2e-b8e6-2415cfd2f682"
          defaultMessage="{name}, verify your identity."
          values={{ name: namePrompt }}
        />
      ) : !requiresIdPhotos ? (
        <FormattedMessage
          id="be6007d8-3dd4-4f9f-92d5-8c117e06ddf4"
          defaultMessage="{name}, take a selfie to verify."
          values={{ name: namePrompt }}
        />
      ) : selfieRequired ? (
        <FormattedMessage
          id="c341ab18-a57c-43f3-b155-66f683f59281"
          defaultMessage="{name}, verify your identity."
          values={{ name: namePrompt }}
        />
      ) : (
        <FormattedMessage
          id="7f766f24-78e4-4677-a503-7ff1dbe90e4f"
          defaultMessage="{name}, capture your ID."
          values={{ name: namePrompt }}
        />
      )}
    </MainHeading>
  );
}
