import {
  CURRENT_EXPERIMENTS,
  EXPERIMENT_GROUP_DEFAULT,
  useExperimentGroup,
} from "common/experiments/group";

type DeviceSelectorVariant = "default" | "new-selector" | "empty";

export function useDeviceSelectorVariant({
  isRetail,
}: {
  isRetail: boolean;
}): DeviceSelectorVariant {
  const experiment = CURRENT_EXPERIMENTS.IDVDeviceSelector;
  const idvDeviceSelectorExpt = useExperimentGroup({
    flag: experiment.flag,
    possibleGroups: [experiment.A, experiment.B],
    precedence: "ld",
  });

  if (!isRetail || idvDeviceSelectorExpt === EXPERIMENT_GROUP_DEFAULT) {
    return "default";
  }

  if (idvDeviceSelectorExpt === experiment.A) {
    return "new-selector";
  }

  return "empty";
}
