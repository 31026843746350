import type { Link as ReactRouterLink } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import type { ComponentProps, ReactNode } from "react";
import classnames from "classnames";

import { Heading, Paragraph } from "common/core/typography";
import { ButtonStyledLink } from "common/core/button/button_styled_link";
import { Badge } from "common/core/badge";
import { useMobileScreenClass } from "common/core/responsive";

import Styles from "./index.module.scss";

export function WorkflowBadge({ isClosing }: { isClosing?: boolean }) {
  return (
    <Badge className={Styles.tileBadge}>
      {isClosing ? (
        <FormattedMessage
          id="51fb6eba-697d-4d24-b2df-94e6826c7785"
          defaultMessage="Closing workflow"
        />
      ) : (
        <FormattedMessage id="51fb6eba-697d-4d24-b2df-94e6826c7785" defaultMessage="Workflow" />
      )}
    </Badge>
  );
}

/**
 * @description
 * Currently 3 Tile variations - full width link centered with no border (workflows), right aligned link with no border (tools), and right aligned link with border (resources).
 */

export function Tile({
  title,
  headingPendoId,
  description,
  image,
  alignLinkRight,
  withFooterBorder,
  link,
  workflowBadge,
}: {
  title: ReactNode;
  headingPendoId?: string;
  description: ReactNode;
  image?: ReactNode;
  workflowBadge?: ReactNode;
  alignLinkRight?: boolean;
  withFooterBorder?: boolean;
  link: ReactNode;
}) {
  return (
    <div className={Styles.tile}>
      <div className={Styles.tileContent}>
        {workflowBadge && workflowBadge}
        <Heading
          data-pendo-id={headingPendoId}
          level="h2"
          textStyle="headingFive"
          className={Styles.tileHeading}
        >
          {title}
        </Heading>
        <div className={Styles.tileDescription}>
          <Paragraph className={Styles.tileDescriptionText}>{description}</Paragraph>
          {image && image}
        </div>
      </div>

      <div
        className={classnames(
          Styles.tileFooter,
          alignLinkRight && Styles.rightAlign,
          withFooterBorder && Styles.withBorder,
        )}
      >
        {link}
      </div>
    </div>
  );
}

type TileLinkProps = ({ href: string; to?: never } | ComponentProps<typeof ReactRouterLink>) &
  Omit<
    ComponentProps<typeof ButtonStyledLink>,
    "buttonColor" | "onClick" | "withIcon" | "href" | "to"
  >;

/**
 * @description
 * Used in 3 Tile variations - primary is always full width, tertiary always has the same icon
 * If behavior ever changes, fullwidth and withIcon could be passed as props
 */

export function TileLink({ children, variant, ...props }: TileLinkProps) {
  const isMobile = useMobileScreenClass();
  return (
    <ButtonStyledLink
      fullwidth={variant === "primary" || (variant === "secondary" && isMobile)}
      variant={variant}
      buttonColor="action"
      withIcon={
        variant === "tertiary" ? { name: "arrow-up-right-square", placement: "right" } : undefined
      }
      {...props}
    >
      {children}
    </ButtonStyledLink>
  );
}
