import { FormattedMessage } from "react-intl";

import { SubHeading } from "common/signer/common";
import { Badge } from "common/core/badge";
import Icon from "common/core/icon";
import { Paragraph, Substyle } from "common/core/typography";
import AlertMessage from "common/core/alert_message";
import { RadioGroup, RadioInput } from "common/core/form/option";
import type { IDVInteraction } from "common/identity_verification/interaction";

import { DeviceOption } from "./device_option";
import Styles from "./index.module.scss";

const noop = () => {};

export function DeviceSelector({
  allowDesktopCapture,
  idvInteraction,
}: {
  allowDesktopCapture: boolean;
  idvInteraction: IDVInteraction;
}) {
  if (!allowDesktopCapture) {
    return (
      <Paragraph className={Styles.deviceInfo}>
        <FormattedMessage
          id="b34341d4-510a-4b41-bc42-25a2838a41bc"
          defaultMessage="For the best verification experience, you'll need to use your mobile phone to take photos of your ID. When you click continue, we'll guide you through the process of switching to your mobile device."
        />
      </Paragraph>
    );
  }
  return (
    <>
      <RadioGroup
        className={Styles.deviceOptions}
        inputsClassname={Styles.deviceOptionsInputs}
        label={
          <SubHeading>
            <FormattedMessage
              id="27263572-44f4-4894-a542-02f1b5c8234a"
              defaultMessage="Choose your device"
            />
          </SubHeading>
        }
        horizontal
      >
        <DeviceOption
          label={
            <>
              <Badge kind="success" className={Styles.badge}>
                <FormattedMessage
                  id="3a5231db-9c88-4549-9d56-f912c4286fda"
                  defaultMessage="Recommended"
                />
              </Badge>
              <div className={Styles.deviceIcon}>
                <Icon name="mobile" size="extraLarge" />
              </div>
              <Substyle textStyle="subtitleSmall">
                <FormattedMessage
                  id="625d9cfc-da85-40ba-87b3-73b02883fc91"
                  defaultMessage="Use mobile phone"
                />
              </Substyle>
              <Paragraph textColor="subtle">
                <FormattedMessage
                  id="736368df-e8d8-4443-8962-ee346de5d0a6"
                  defaultMessage="Fast & reliable verification"
                />
              </Paragraph>
            </>
          }
          radio={
            <RadioInput
              value="use-mobile"
              checked={!idvInteraction.configuration.enableDesktopCapture}
              onClick={() => idvInteraction.onConfigurationChange({ enableDesktopCapture: false })}
              onChange={noop}
            />
          }
        />
        <DeviceOption
          label={
            <>
              <div className={Styles.deviceIcon}>
                <Icon name="computer" size="extraLarge" />
              </div>
              <Substyle textStyle="subtitleSmall">
                <FormattedMessage
                  id="008fb696-d731-458e-a83d-fbdbc2fa8872"
                  defaultMessage="Use this device"
                />
              </Substyle>
              <Paragraph textColor="subtle">
                <FormattedMessage
                  id="d56c104b-2cb4-42f5-a7ad-5137fc720711"
                  defaultMessage="Webcam required"
                />
              </Paragraph>
            </>
          }
          radio={
            <RadioInput
              value="use-desktop"
              checked={idvInteraction.configuration.enableDesktopCapture}
              onClick={() => idvInteraction.onConfigurationChange({ enableDesktopCapture: true })}
              onChange={noop}
              data-automation-id="use-this-device-radio"
            />
          }
        />
      </RadioGroup>

      {idvInteraction.configuration.enableDesktopCapture && (
        <AlertMessage kind="warning" className={Styles.deviceOptionsWarning}>
          <FormattedMessage
            id="ebaf6667-0804-46a4-adc9-8310cd848aeb"
            defaultMessage="It's recommended to use a mobile device for capturing ID photos. Desktop capture may not be as reliable."
          />
        </AlertMessage>
      )}
    </>
  );
}
